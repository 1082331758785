import { useEffect, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'

import imageUrl from "../../assets/img/logo_sant-tomas.png"

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 25,
        alignContent: 'flex-start',
    },
    tableHeader: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
    },
    tableColHeader1: {
        width: '20%',
        height: 70,
        borderColor: '#000',
        borderWidth: 1,
    },
    tableColHeader2: {
        width: '60%',
        height: 70,
        borderColor: '#000',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableColHeader3: {
        width: '20%',
        height: 70,
        borderColor: '#000',
        borderWidth: 1,
    },
    tableFileHeader1: {
        height: '33%',
        flexDirection: 'row',
        borderColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableFileHeader2: {
        height: '33%',
        flexDirection: 'row',
        borderColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableFileHeader3: {
        height: '33%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableCellHeader: {
        fontSize: 10,
        fontWeight: 'bold',
    },
    tableImage: {
        width: '90%',
        height: 'auto',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainPdf: {
        flexDirection: 'column',
        fontSize: 12,
        paddingTop: 10,
    },
    tableMain: {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 30,
        flexDirection: 'column',
        alignContent: 'flex-start',
    },
    tableRow: {
        flexDirection: 'row',
    },
    titleCel: {
        width: '100%',
        backgroundColor: '#6c7ae0',
        color: '#fff',
        borderWidth: 1,
        padding: 2,
        borderColor: '#6c7ae0',
    },
    tableCell1: {
        width: '40%',
        borderColor: '#000',
        borderTop: 1,
        borderLeft: 1,
        padding: 2,
    },
    tableCell2: {
        width: '60%',
        borderColor: '#000',
        borderTop: 1,
        borderRight: 1,
        borderLeft: 1,
        padding: 2,
    },
    tableCell1Last: {
        width: '40%',
        borderColor: '#000',
        borderTop: 1,
        borderLeft: 1,
        borderBottom: 1,
        padding: 2,
    },
    tableCell2Last: {
        width: '60%',
        borderColor: '#000',
        borderTop: 1,
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        padding: 2,
    }
})

const AltaUserPdf = ({ user }) => {

    const [ userData, setUserData ] = useState({})

    useEffect(() => {
        setUserData(user)
    }, [user])

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.tableHeader}>
                    <View style={styles.tableColHeader1}>
                        <Image src={imageUrl} style={styles.tableImage} />
                    </View>
                    <View style={styles.tableColHeader2}>
                        <Text style={styles.tableCellHeader}>COMUNICACIÓ ALTA USUARI</Text>
                    </View>
                    <View style={styles.tableColHeader3}>
                        <View style={styles.tableFileHeader1}>
                            <Text style={styles.tableCellHeader}>IMP4-IT14-105</Text>
                        </View>
                        <View style={styles.tableFileHeader2}>
                            <Text style={styles.tableCellHeader}>Rev.1</Text>
                        </View>
                        <View style={styles.tableFileHeader3}>
                            <Text style={styles.tableCellHeader}>Març/2014</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.mainPdf}>
                    <View style={styles.tableMain}>
                        <View style={styles.tableRow}>
                            <View style={styles.titleCel}>
                                <Text>DADES SERVEI</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1Last}>
                                <Text>SERVEI:</Text>
                            </View>
                            <View style={styles.tableCell2Last}>
                                <Text>Espai Viu</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.tableMain}>
                        <View style={styles.tableRow}>
                            <View style={styles.titleCel}>
                                <Text>DADES USUARI</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>NOM:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userName}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>COGNOMS:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userLastName1} {userData.userLastName2}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>ADREÇA:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userTipus} {userData.userDireccio}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>CODI POSTAL:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userPoblacio?.substring(1, 6)}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>POBLACIÓ:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userPoblacio?.substring(8)}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>TELÈFON 1:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal1Telefon}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>TELÈFON 2:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal2Telefon}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>CORREU ELECTRÒNIC:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userEmail}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>DATA NAIXEMENT:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userDataNaixement && new Date(userData.userDataNaixement).toLocaleDateString()}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>SEXE:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userSexe}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>D.N.I.:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userDNI}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>ALTA SERVEI:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userDataAlta && new Date(userData.userDataAlta).toLocaleDateString()}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>FORMA PAGAMENT:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userSistemaPagament && userData.userSistemaPagament.payTypeName}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1Last}>
                                <Text>NÚMERO IBAN:</Text>
                            </View>
                            <View style={styles.tableCell2Last}>
                                <Text>{userData.userIBAN}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.tableMain}>
                        <View style={styles.tableRow}>
                            <View style={styles.titleCel}>
                                <Text>DADES FAMILIARS</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>PARENTESC:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal1Parentesc}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>NOM:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal1Name}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>COGNOMS:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal1LastName1} {userData.userResponsableLegal1LastName2}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>PARENTESC:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal2Parentesc}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1}>
                                <Text>NOM:</Text>
                            </View>
                            <View style={styles.tableCell2}>
                                <Text>{userData.userResponsableLegal2Name}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell1Last}>
                                <Text>COGNOMS:</Text>
                            </View>
                            <View style={styles.tableCell2Last}>
                                <Text>{userData.userResponsableLegal2LastName1} {userData.userResponsableLegal2LastName2}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default AltaUserPdf