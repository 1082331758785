import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import Select from 'react-select'

import Modal from '../../components/Modal/Modal'

import getReferentById from '../../services/referents/getReferentById'
import postReferent from '../../services/referents/postReferent'
import putReferent from '../../services/referents/putReferent'
import getAllProfessionals from '../../services/professionals/getAllProfessional'
import getAllUsers from '../../services/users/getAllUsers'

import styles from './NewReferent.module.css'

const NewReferent = () => {
    
    let navigate = useNavigate()

    const { id } = useParams()
    const [ newReferent, setNewReferent ] = useState({})
    const [ professionals, setProfessionals ] = useState([])
    const [ active, setActive ] = useState(true)
    const [ users, setUsers ] = useState([])
    const [ selectedOptions, setSelectedOptions ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllProfessionals()
        .then(professionals => {
            setProfessionals(professionals)
        })
        if (id !== undefined) {
            getReferentById(id)
            .then(referent => {
                setNewReferent(referent)
                getAllUsers()
                .then(users => {
                    setUsers(users)
                    // Actualizar el estado de los checkboxes para reflejar los usuarios seleccionados en la brigada
                    let selectedUsers = users.filter(user => referent.referentUsersId.includes(user._id))
                    let selectedOptions = selectedUsers.map(user => ({
                        value: user._id,
                        label: `${user.userLastName1} ${user.userLastName2}, ${user.userName}` 
                    }))
                    setSelectedOptions(selectedOptions)
                })
                setActive(referent.referentActive)
            })
        } else {
            getAllUsers()
                .then(users => {
                    users.sort((a, b) => a.userLastName1.localeCompare(b.userLastName1))
                    setUsers(users)
            })
        }
    },[id])

    const handleChangeUsers = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    };

    const userOptions = users.map(user => ({
        value: user._id,
        label: `${user.userLastName1} ${user.userLastName2}, ${user.userName}` 
    }))

    const handleSubmit = (event) => {
        event.preventDefault()
        let form = event.target
        let formData = new FormData(form)
        formData.append('referentCompanyId', LocalStorage.getItem("professionalCompanyId"))
        let formJson = Object.fromEntries(formData.entries())
        let arraySelected = selectedOptions.map(element => {
            return element.value
        })
        formJson.referentUsersId = arraySelected
        if (id !== undefined) {
            putReferent(formJson, id) 
            .then(newReferent => {
                setNewReferent(newReferent)
            })
        } else {
            postReferent(formJson) 
            .then(newReferent => {
                setNewReferent(newReferent)
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/referents", { replace: true })
        },3000)
    }

    const handleChange = (event) => {
        if (event.target.id === "referentActive") {
            setActive(event.target.checked)
        } else {
            setNewReferent(event.target.value)
        }
    }

    return (
        <div className={styles.container}>
            <h2>{id ? "Editar referents" : "Alta nou referents"}</h2>
            <div className={`${styles.modal} ${disable && styles.disable}`}>
                <Modal error={error} id={id} text={id ? "Referents modificats correctament" : "Referents creats correctament"}></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="referentProfessionalId">Referents</label>
                <select onChange={handleChange} name="referentProfessionalId" value={newReferent.referentProfessionalId} id="referentProfessionalId">
                    {
                        professionals && professionals.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.professionalName} {element.professionalLastName1} {element.professionalLastName2}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="referentUsersId">Usuaris</label>
                <Select
                    name="referentUsersId"
                    value={selectedOptions}
                    onChange={handleChangeUsers}
                    options={userOptions}
                    isMulti
                    placeholder="- Selecciona -"
                />
                <div className={styles.btn_container}>
                    <button className={ styles.btn_add} type='submit'>{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
            <div className={styles.selectedUsers}>
                <h2>Usuaris Seleccionats</h2>
                <ul className={styles.upkeeplist}>
                    {selectedOptions.map(option => {
                        return <li key={option.value} className={styles.upkeeplistitem} >{option.label}</li>
                    })}
                </ul>
            </div>
        </div>
    )
} 

export default NewReferent