import { LocalStorage } from '../localStorage/LocalStorage.services'

const fetchFile = async (filename) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/document/get-url`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token"),
                    'filename': filename
                },
            }
        )
        if (response.status === 400 || response.status === 401) {
            localStorage.clear() // Netejar localStorage
            window.location.href = "/login" // Redirigir al login
            return {message: "Unauthorized"}
        } else if (response.ok){
            const data = await response.json()
            if (data.url) {
                // Obre l'arxiu utilitzant la presigned URL
                window.open(data.url, "_blank")
            } else {
                console.error("No es pot obtenir la URL:", data.error)
            }
        }
    } catch (err) {
        console.error("Error d'accés a l'arxiu:", err)
    }
}

export default fetchFile