import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { catalan_calendar } from '../../utils/editDatePicker'
import DatePicker from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker"
import Modal from '../../components/Modal/Modal'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import getAllProfessionals from '../../services/professionals/getAllProfessional'
import getAllSeguimentType from '../../services/seguimentType/getAllSeguimentType'
import getAllPayType from '../../services/payType/getAllPayType'
import getSeguimentById from '../../services/seguiments/getSeguimentById'
import putSeguiment from '../../services/seguiments/puSeguiment'

import styles from './NewSeguiment.module.css'

const EditSeguiment = () => {

    const { id } = useParams()
    const [ newSeguiment, setNewSeguiment ] = useState({})
    const [ users, setUsers ] = useState([])
    const [ professionals, setProfessionals ] = useState([])
    const [ seguimentTypes, setSeguimentTypes ] = useState([])
    const [ payType, setPayType ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ valuesPlanning, setValuesPlanning ] = useState(null)
    const [ valuesDo, setValuesDo ] = useState(null)
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
        getAllUsers()
        .then(users => {
            setUsers(users)
        })
        getAllProfessionals()
        .then(professionals => {
            setProfessionals(professionals)
        })
        getAllSeguimentType()
        .then(seguimentType => {
            setSeguimentTypes(seguimentType)
        })
        getAllPayType()
        .then(payType => {
            setPayType(payType)
        })
        getSeguimentById(id)
        .then(seguimentType => {
            setNewSeguiment(seguimentType)
            setValuesPlanning(seguimentType.seguimentPlanningDate)
            setValuesDo(seguimentType.seguimentDoDate)
        })
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        const formJson = Object.fromEntries(formData.entries())
        formJson.seguimentDoDate = valuesDo
        formJson.seguimentStatus = false
        if (formJson.seguimentPayId === "selecciona") {
            setError("Selecciona un tipus de pagament")
            setDisable(false)
            setTimeout(() => {
                setError("")
                setDisable(true)
            },3000)
            return
        } else {
            putSeguiment(formJson, id) 
            .then(newSeguiment => {
                setNewSeguiment(newSeguiment)
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                },3000)
            })
        }
    }

    const handleChange = (event) => {
        setNewSeguiment(event.target.value)
    }

    return (
        <div className={styles.container}>
            <h2>Enregistrar Seguiment</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} text="Seguiment guardat correctament"></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="seguimentUserId">Usuari</label>
                <select onChange={handleChange} name="seguimentUserId" value={newSeguiment.seguimentUserId} id="seguimentUserId" disabled>
                    <option value="selecciona">- Selecciona -</option>
                    {
                        users.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.userName} {element.userLastName1} {element.userLastName2}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentProfessionalId">Professional</label>
                <select onChange={handleChange} name="seguimentProfessionalId" value={newSeguiment.seguimentProfessionalId} id="seguimentProfessionalId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        professionals.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.professionalName} {element.professionalLastName1} {element.professionalLastName2}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentType">Tipus seguiment</label>
                <select onChange={handleChange} name="seguimentType" value={newSeguiment.seguimentType} id="seguimentType" disabled>
                    <option value="selecciona">- Selecciona -</option>
                    {
                        seguimentTypes.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.seguimentTypeName} ({element.seguimentTypePrice} €)</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentPlanningDate">Planificat</label>
                <DatePicker   
                    plugins={[
                        <TimePicker position="bottom" hideSeconds/>
                    ]} 
                    style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: "100%",
                        padding: "0.5em",
                        backgroundColor: "aliceblue",
                        borderRadius: ".3rem",
                        border: "none",
                    }}
                    containerStyle={{
                        width: "100%"
                    }}
                    calendarPosition="bottom-center" 
                    locale={catalan_calendar}
                    displayWeekNumbers="enable"
                    numberOfMonths={3}
                    weekStartDayIndex={1}
                    value={valuesPlanning} 
                    format="DD/MM/YYYY HH:mm"
                    onChange={setValuesPlanning}
                    disabled
                />
                <label htmlFor="seguimentDoDate">Realitzat</label>
                <DatePicker  
                    plugins={[
                        <TimePicker position="bottom" hideSeconds/>
                    ]}  
                    style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: "100%",
                        padding: "0.5em",
                        backgroundColor: "aliceblue",
                        borderRadius: ".3rem",
                        border: "none",
                    }}
                    containerStyle={{
                        width: "100%"
                    }}
                    calendarPosition="bottom-center" 
                    locale={catalan_calendar}
                    displayWeekNumbers="enable"
                    numberOfMonths={1}
                    weekStartDayIndex={1}
                    value={valuesDo} 
                    format="DD/MM/YYYY HH:mm"
                    onChange={setValuesDo}
                    required
                />
                <label htmlFor="seguimentBody">Seguiment</label>
                <textarea onChange={handleChange} name="seguimentBody" value={newSeguiment.seguimentBody} rows={10}></textarea>
                <label htmlFor="seguimentPayId">Pagament</label>
                <select onChange={handleChange} name="seguimentPayId" value={newSeguiment.seguimentPayId} id="seguimentPayId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        payType.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.payTypeName}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentPlanningStatus">Estat seguiment</label>
                <select onChange={handleChange} name="seguimentPlanningStatus" value={newSeguiment.seguimentPlanningStatus} id="seguimentPlanningStatus">
                    <option value="0">Pendent</option>
                    <option value="1">Presentat</option>
                    <option value="2">No presentat</option>
                    <option value="3">Cancel·lada per família</option>
                    <option value="4">Cancel·lada per Espai Viu</option>
                </select>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>Guardar</button>
                </div>
            </form>
        </div>
    )
}

export default EditSeguiment