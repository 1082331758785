import { useEffect, useState } from 'react'
import moment from 'moment'

import styles from './UserData.module.css'

const UserData = ({ data }) => {

    const [ user, setUser ] = useState({})

    useEffect(() => {
        setUser(data)
    },[data])

    const data_inicial = user.userDataAlta ? new Date(user.userDataAlta).toLocaleDateString() : ""
    const data_final = user.userDataBaixa ? new Date(user.userDataBaixa).toLocaleDateString() : ""
    const data_naixement = user.userDataNaixement ? new Date(user.userDataNaixement).toLocaleDateString() : ""

    // Càlcul d'anys
    // let naixement = moment(user.userDataNaixement)
    let avui = moment()
    let anys = user.userDataNaixement ? avui.diff(moment(user.userDataNaixement),"years") : ""

    return (
        <div className={styles.userdata}>
            <div className={styles.column}>
                <p><span>Nom</span><span>{user.userName}</span></p>
                <p><span>Cognom 1</span><span>{user.userLastName1}</span></p>
                <p><span>Cognom 2</span><span>{user.userLastName2}</span></p>
                <p><span>DNI</span><span>{user.userDNI}</span></p>
                <p><span>Data Naixement</span><span>{data_naixement}</span></p>
                <p><span>Edat</span><span>{anys && `${anys} anys`}</span></p>
                <p><span>Sexe</span><span>{user.userSexe}</span></p>
                <p><span>Discapacitat</span><span>{user.userCertificatDiscapacitat}</span></p>
                <p><span>Diagnostic</span><span>{user.userDiagnostic}</span></p>
                <p><span>Escola</span><span>{user.userEscola}</span></p>
                <p><span>Informació complementària</span><span>{user.userInformacio}</span></p>
                <p><span>Direcció</span><span>{user.userTipus} {user.userDireccio}</span></p>
                <p><span>Població</span><span>{user.userPoblacio}</span></p>
                <p><span>Email contacte</span><span>{user.userEmail}</span></p>
            </div>
            <div className={styles.column}>
                <p><span>Data alta</span><span>{data_inicial}</span></p>
                <p><span>Data baixa</span><span>{data_final}</span></p>
                <p><span>Motiu consulta</span><span>{user.userMotiuConsulta}</span></p>
                <hr></hr>
                <p><span>Familiar 1</span><span>{user.userResponsableLegal1Name} {user.userResponsableLegal1LastName1} {user.userResponsableLegal1LastName2} {user.userResponsableLegal1Parentesc && `(${user.userResponsableLegal1Parentesc})`} </span></p>
                <p><span>Telèfon 1</span><span>{user.userResponsableLegal1Telefon}</span></p>
                <p><span>Email 1</span><span>{user.userResponsableLegal1Email}</span></p>
                <p><span>Familiar 2</span><span>{user.userResponsableLegal2Name} {user.userResponsableLegal2LastName1} {user.userResponsableLegal2LastName2} {user.userResponsableLegal2Parentesc && `(${user.userResponsableLegal2Parentesc})`}</span></p>
                <p><span>Telèfon 2</span><span>{user.userResponsableLegal2Telefon}</span></p>
                <p><span>Email 2</span><span>{user.userResponsableLegal2Email}</span></p>
                <hr></hr>
                <p><span>Pagament</span><span>{user.userSistemaPagament && user.userSistemaPagament.payTypeName}</span></p>
                <p><span>IBAN</span><span>{user.userIBAN}</span></p>
                <p><span>Observacions</span><span>{user.userObservacions}</span></p>
            </div> 
        </div>
    )
}

export default UserData