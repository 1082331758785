import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllSeguimentType from '../../services/seguimentType/getAllSeguimentType'
import desactiveSeguimentType from '../../services/seguimentType/desactiveSeguimentType'

import styles from './SeguimentType.module.css'

const SeguimentType = () => {

  const componentRef = useRef()
  
  const [ seguimentType, setseguimentType ] = useState([])
  const [ seguimentTypeFiltered, setSeguimentTypeFiltered ] = useState([])
  const [ update, setUpdate ] = useState(false)

  useEffect(() => {
    getAllSeguimentType()
    .then(seguimentType => {
      setseguimentType(seguimentType)
      setSeguimentTypeFiltered(seguimentType)
    })
  },[update])

  // Función desactivar compañia (como un deleted pero si borrar para conservar informacion)
  const handleDesactive = (event) => {
    desactiveSeguimentType(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula de tipus seguiments",
    onafterprint: () => alert('Print success')
  })

  const titles = [ "Tipus Seguiment", "Preu", "Durada", "Estat", "Accions" ]

   // Función para hacer funcionar el filtro de elementos => por Seguiment Tipus
   const handleChangeSeguimentType = (value) => {
    if (value.length !== 0) {
      let seguimentTypeFiltereds = seguimentType.filter(element =>  value.includes(element._id)) 
      setSeguimentTypeFiltered(seguimentTypeFiltereds)
    } else {
      setSeguimentTypeFiltered(seguimentType)
    }
  }

  // Placeholder del filtro de Seguiment Tipus
  const placeholderFilterSeguimentType = "Filtre tipus seguiment"

  // Array con los elementos del filtro de Seguiment Tipus
  const dataSeguimentType = seguimentType.map(element => {
    return {
      label: `${element.seguimentTypeName}`,
      value: element._id
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.main}>
      <div className={styles.filters}>
            <div className={styles.multiselector}>
              <Filter
                data={dataSeguimentType} 
                handleChange={handleChangeSeguimentType}
                placeholderFilter={placeholderFilterSeguimentType}
                type='multiple'
              />
            </div>
          </div>
        <div className={styles.button}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button path="/seguiment-type/new-seguimenttype" icon={"fa-circle-plus"}/>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                seguimentTypeFiltered.map(element => {
                  let url = '/seguiment-type/' + element._id
                  let time = element.seguimentTypeTime ? element.seguimentTypeTime + ":00" : "00:00:00"
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>{element.seguimentTypeName}</td>
                      <td className={styles.columns}>{element.seguimentTypePrice} €</td>
                      <td className={styles.columns}>{time}</td>
                      <td className={styles.columns}>{element.seguimentTypeActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SeguimentType
