import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useReactToPrint } from 'react-to-print'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import esLocale from '@fullcalendar/core/locales/ca'
import dayjs from 'dayjs'
import locale_es from 'dayjs/locale/es'
import Filter from '../../components/Filter/Filter'
import Button from '../../components/UI/Button'
import getAllSeguiment from '../../services/seguiments/getAllSeguiment'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import getAllProfessional from '../../services/professionals/getAllProfessional'

import styles from './CalendarSeguiments.module.css'

dayjs.locale(locale_es)
dayjs().format('DD/MM/YYYY')

const CalendarSeguiments = () => {

  const componentRef = useRef()
  const navigate = useNavigate()

  const [ seguiments, setSeguiments ] = useState([])
  const [ seguimentsFiltered, setSeguimentsFiltered ] = useState([])
  const [ seguimentsFiltered1, setSeguimentsFiltered1 ] = useState([])
  const [ seguimentsFiltered2, setSeguimentsFiltered2 ] = useState([])
  const [ seguimentsFiltered3, setSeguimentsFiltered3 ] = useState([])
  const [ seguimentsFiltered4, setSeguimentsFiltered4 ] = useState([])
  const [ seguimentsFiltered5, setSeguimentsFiltered5 ] = useState([])
  const [ professionals, setProfessionals ] = useState(null)
  const [ users, setUsers ] = useState(null)
  const [ headquarters, setHeadquarters ] = useState([])

  useEffect(() => {
    getAllSeguiment()
    .then(seguiments => {
      const seguimentsProfessional = seguiments.filter(element => element.seguimentProfessionalId._id === LocalStorage.getItem('id'))
      setSeguiments(seguiments)
      setSeguimentsFiltered(seguiments)
      setSeguimentsFiltered1(seguiments)
      setSeguimentsFiltered2(seguimentsProfessional)
      setSeguimentsFiltered3(seguiments)
      setSeguimentsFiltered4(seguiments)
      setSeguimentsFiltered5(seguiments)
    })
    getAllHeadquarters()
    .then(headquarters => {
      setHeadquarters(headquarters)
    })
    getAllUsers()
    .then(users => {
      setUsers(users)
    })
    getAllProfessional()
    .then(professionals => {
      setProfessionals(professionals)
    })
  },[])

  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(seguimentsFiltered1, seguimentsFiltered2, seguimentsFiltered3, seguimentsFiltered4, seguimentsFiltered5)
    setSeguimentsFiltered(uniqueValues)
  }, [seguimentsFiltered1, seguimentsFiltered2,seguimentsFiltered3, seguimentsFiltered4, seguimentsFiltered5])


  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value))
    })
  }

  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "Taula de inspeccions",
      onafterprint: () => alert('Print success')
  })

  // Función para hacer funcionar el filtro de elementos => por estat
  const handleChangeState = (value) => {
    if (value.length !== 0) {
      let seguimentsFiltered = seguiments.filter(element => value.includes(element.seguimentStatus))
      setSeguimentsFiltered1(seguimentsFiltered)
    } else {
      setSeguimentsFiltered1(seguiments)
    }
  }

  // Placeholder del filtro de estat
  const placeholderStatus = "Filtre situació"

  // Array con los elementos del filtro de estat
  const dataStatus = [
    {
      label: "Pendent",
      value: true,
    },
    {
      label: "Realitzat",
      value: false,
    },
  ]

  // Función para hacer funcionar el filtro de elementos => por professional
  const handleChangeProfessional = (value) => {
    if (value.length !== 0) {
      let seguimentsFiltered = seguiments.filter(element =>  value.includes(element.seguimentProfessionalId._id))
      setSeguimentsFiltered2(seguimentsFiltered)
    } else {
      setSeguimentsFiltered2(seguiments)
    }
  }

  // Placeholder del filtro de professional
  const placeholderFilterProfessionals = "Filtre professional"

  // Array con los elementos del filtro de professional
  const dataProfessionals = professionals && professionals.map(element => {
    return {
      label: `${element.professionalName} ${element.professionalLastName1} ${element.professionalLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por usuari
  const handleChangeUser = (value) => {
    if (value.length !== 0) {
      let usersFiltered = seguiments.filter(element => value.includes(element.seguimentUserId._id))
      setSeguimentsFiltered3(usersFiltered)
    } else {
      setSeguimentsFiltered3(seguiments)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterUsers = "Filtre usuari"

  // Array con los elementos del filtro de usuaris
  const dataUsers = users && users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id,
    }
  }) 

  // Función para hacer funcionar el filtro de elementos => por headquarters
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      let professionalsFiltered = seguiments.filter(element => value.includes(element.seguimentUserId.userAreaId)) 
      setSeguimentsFiltered4(professionalsFiltered)
    } else {
      setSeguimentsFiltered4(seguiments)
    }
  }

  // Placeholder del filtro de headquarters
  const placeholderFilterHeadquarters = "Filtre àrea"

  // Array con los elementos del filtro de headquarters
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

    // Función para hacer funcionar el filtro de elementos => por situacio
    const handleChangeSituacio = (value) => {
      if (value.length !== 0) {
        let situacioFiltered = seguiments.filter(element => element.seguimentPlanningStatus && value.includes(element.seguimentPlanningStatus))
        setSeguimentsFiltered5(situacioFiltered)
      } else {
        setSeguimentsFiltered5(seguiments)
      }
    }
  
    // Placeholder del filtro de headquarters
    const placeholderFilterSituacio = "Filtre estat seguiment"
  
    // Array con los elementos del filtro de headquarters
    const dataSituacio = [
      {
        label: "Pendent",
        value: "0",
      },
      {
        label: "Presentat",
        value: "1",
      },
      {
        label: "No presentat",
        value: "2",
      },
      {
        label: "Cancel·lat per família",
        value: "3",
      },
      {
        label: "Cancel·lat per Espai Viu",
        value: "4",
      },
    ]

  const events = seguimentsFiltered && seguimentsFiltered.map(element => {
    const nameUser = `${element.seguimentUserId.userName} ${element.seguimentUserId.userLastName1} ${element.seguimentUserId.userLastName2}`
    const id = element._id
    const time = element.seguimentPlanningTime ? element.seguimentPlanningTime : "00"
    const duration = time === "00" ? "45" : time
    const endDate = new Date(element.seguimentPlanningDate)
    endDate.setMinutes(endDate.getMinutes() + parseInt(duration))
 
    return {
      id: id,
      title: nameUser,
      start: element.seguimentPlanningDate,
      end: endDate,
      backgroundColor: element.seguimentStatus === false ? "#4AF987" : "red",
    }
  })
  
  const handleNewEvent = () => {
      navigate("/seguiments/new-seguiment")
  }

  const handleEditEvent = (event) => {
      const id = event.el.fcSeg.eventRange.def.publicId
      navigate(`/seguiments/${id}`)
  }

  const professionalSelect = LocalStorage.getItem('id')

  return (
      <div className={styles.container} ref={componentRef} >
          <div className={styles.main}>
              <div className={styles.filters}>
                <div className={styles.multiselector}>
                  <Filter
                    data={dataUsers} 
                    handleChange={handleChangeUser}
                    placeholderFilter={placeholderFilterUsers}
                    type='multiple'
                  />
                </div>
                <div className={styles.multiselector}>
                  <Filter
                    data={dataProfessionals} 
                    handleChange={handleChangeProfessional}
                    placeholderFilter={placeholderFilterProfessionals}
                    value={professionalSelect}
                    type='multiple'
                  />
                </div>
                <div className={styles.multiselector}>
                  <Filter
                    data={dataStatus} 
                    handleChange={handleChangeState}
                    placeholderFilter={placeholderStatus}
                    type='multiple'
                  />
                </div>
                <div className={styles.multiselector}>
                  <Filter
                    data={dataHeadquarters} 
                    handleChange={handleChangeHeadquarter}
                    placeholderFilter={placeholderFilterHeadquarters}
                    type='multiple'
                  />
                </div>
                <div className={styles.multiselector}>
                  <Filter
                    data={dataSituacio} 
                    handleChange={handleChangeSituacio}
                    placeholderFilter={placeholderFilterSituacio}
                    type='multiple'
                  />
                </div>
              </div>
          </div>
          <div className={styles.menubuttons}>
              <div className={styles.button}>
                  <Button path="/seguiments" icon={"fa-list"}/>
                  <Button handleOnClick={handlePrint} icon={"fa-print"}/>
                  <Button path="/seguiments/new-seguiment" icon={"fa-circle-plus"}/>
              </div>
          </div>
          <div className={styles.calendar}>
              <FullCalendar
                  plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin ]}
                  initialView="dayGridMonth"
                  locale={ esLocale }
                  headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  editable={true}
                  events={events}
                  dateClick={handleNewEvent}
                  eventClick={handleEditEvent}
                  slotMinTime={"07:00:00"}
                  slotMaxTime={"21:00:00"}
                  expandRows
                  height={800}
                  allDaySlot={false}
                  aspectRatio={1.2}
                  themeSystem="bootstrap5"
                  eventDurationEditable={false}
                  defaultTimedEventDuration={"00:45:00"}
              />
          </div>
      </div>
  )
}

export default CalendarSeguiments
