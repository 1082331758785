import { Link } from 'react-router-dom'

import styles from './UserTable.module.css'

const UserTable = ({ titles, seguiments }) => {

    return (
        <div className={`${styles.table} ${styles.ver3}`}>
            <table data-vertable="ver3" className={styles.etable}>
                <thead>
                <tr className={`${styles.rows} ${styles.head}`}>
                    {
                    titles.map((element, index) =>{
                        return <th className={styles.columns} key={index}>{element}</th>
                    })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    seguiments && seguiments.map(element => {
                    let url = '/seguiments/' + element._id
                    return (
                        <tr className={styles.rows} id={element._id} key={element._id}>
                            <td className={styles.columns}>{new Date(element.seguimentPlanningDate).toLocaleDateString()}</td>
                            <td className={styles.columns}>{element.seguimentDoDate && new Date(element.seguimentDoDate).toLocaleDateString()}</td>
                            <td className={styles.columns}>{element.seguimentType.seguimentTypeName}</td>
                            <td className={styles.columns}><Link to={url}>{element.seguimentBody}</Link></td>
                            <td className={styles.columns}>{element.seguimentProfessionalId.professionalName} {element.seguimentProfessionalId.professionalLastName1} {element.seguimentProfessionalId.professionalLastName2}</td>
                            <td className={styles.columns}>{element.seguimentStatus === true ? "Pendent" : "Realitzat"}</td>
                        </tr>
                    )
                    })
                }
                </tbody>
            </table>
        </div>
    )  
}

export default UserTable