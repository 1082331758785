import { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router-dom"
import moment from 'moment'

import Porlet from '../../components/Porlet/Porlet'
import UserTable from './UserTable'
import UserData from './UserData'
import UserDocuments from './UserDocuments'

import getUserById from '../../services/users/getUserById'
import getAllSeguimentByUser from '../../services/seguiments/getAllSeguimentByUser'

import avatarGirl from "../../assets/img/kid_girl.png"
import avatarBoy from "../../assets/img/kid_man.png"
import styles from './ViewUser.module.css'

const ViewUser = () => {

    const componentRef = useRef()

    const { id } = useParams()
    const [ newUser, setNewUser ] = useState({})
    const [ seguiments, setSeguiments ] = useState([])
    const [ seguimentsDo, setSeguimentsDo ] = useState([])

    const [ pending, setPending ] = useState(false)
    const [ update, setUpdate ] = useState(false)
    const [ screen, setScreen ] = useState(1)

    useEffect(() => {
        setPending(true)
        getUserById(id)
        .then(user => {
            setNewUser(user)
        })
        getAllSeguimentByUser(id)
        .then(seguiments => {
            setSeguiments(seguiments)
            const doSeguiments = seguiments.filter(element => element.seguimentStatus === false)
            setSeguimentsDo(doSeguiments)
        })
        setPending(false)
    },[id, update])

    // Càlcul dies des de l'últim seguiment
    const last_seguiment = seguimentsDo && seguimentsDo.length > 1 ? seguimentsDo.sort(function (a, b) {return a.seguimentDoDate - b.seguimentDoDate}) : seguimentsDo
    const a = moment()
    const b = last_seguiment.length !== 0 ? moment(last_seguiment[0]?.seguimentDoDate) : moment()

    // Càlcul dies fins al pròxim seguiment
    const next_seguiment = seguiments && seguiments.sort(function (a, b) {return a.seguimentPlanningDate - b.seguimentPlanningDate})
    const c = moment()
    const d = seguiments && moment(next_seguiment[0]?.seguimentPlanningDate)

    // Càlcul seguiments pendents
    const seguimentsPendents = seguiments && seguiments.filter(element => element.seguimentStatus === true)

    // Càlcul seguiments endarrerits
    const seguimentsEndarrerits = seguiments && seguiments.filter(element => element.seguimentStatus === false)

    // Càlcul seguiments fets
    const seguimentsFets = seguiments && seguiments.filter(element => element.seguimentDoDate)

    let datesInfo = [
        {
            name: "Nº Seguiments Fets",
            data: seguimentsFets.length,
            link: "/seguiments",
            type: "info",
            id: id,
            icon: "fa-clipboard"
        },
        {
            name: "Nº Seguiment Pendents",
            data: seguimentsPendents.length,
            link: "/seguiments",
            type: "info",
            id: id,
            icon: "fa-clock"
        },
    ]

    let datesAlert = [
        {
            name: "Nº Seguiments Endarrerits",
            data: seguimentsEndarrerits.length,
            link: "/seguiments",
            type: "alerta",
            id: id,
            icon: "fa-user-xmark"
        },
        {
            name: "Dies últim seguiment",
            data: last_seguiment.length !== 0 && a.diff(b, 'days') ? a.diff(b, 'days') : String.fromCharCode(216), 
            link: "/seguiments",
            type: "alerta",
            id: id,
            icon: "fa-calendar"
        },
        {
            name: "Dies pròxim seguiment",
            data: next_seguiment.length !== 0 && d.diff(c, 'days') >= 0 ? d.diff(c, 'days') : String.fromCharCode(216), 
            link: "/seguiments",
            type: "alerta",
            id: id,
            icon: "fa-calendar"
        },
    ]

    const titles = [ "Planificat", "Realitzat", "Seguiment", "Descripció", "Professional", "Estat" ]

    return (
        <div className={styles.container}>
            <div className={styles.header_user}>
                <div className={styles.avatar}>
                    <img src={newUser.userSexe === "Nena" || newUser.userSexe === "Femella" ? avatarGirl : avatarBoy} alt="logo"/>
                </div>
                <h4>{newUser.userName} {newUser.userLastName1} {newUser.userLastName2}</h4>
                <div className={styles.menu_user}>
                    <div className={styles.menu_lateral}>
                        <button className={screen === 2 ? styles.buttonLateral2 : styles.buttonLateral} onClick={() => setScreen(2)}>Dades personals</button>
                        <button className={screen === 1 ? styles.buttonLateral2 : styles.buttonLateral} onClick={() => setScreen(1)}>Seguiments</button>
                        <button className={screen === 3 ? styles.buttonLateral2 : styles.buttonLateral} onClick={() => setScreen(3)}>Documents</button>
                    </div>
                    {

                    }
                </div>
            </div>
            <div className={styles.main_user}>
                <div className={styles.porlets_user}>
                    <div className={styles.container_line}>
                        {
                        datesInfo.map((element, index) => {
                            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon}></Porlet>
                        })
                        }
                        {
                        datesAlert.map((element, index) => {
                            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon}></Porlet>
                        })
                        }
                    </div>
                </div>
                <div className={styles.datas}>
                    <div className={styles.listElements} ref={componentRef}>
                        {
                            screen === 1 && <UserTable titles={titles} seguiments={seguiments}></UserTable>
                        }
                        {
                            screen === 2 && <UserData data={newUser}></UserData>
                        }
                        {
                            screen === 3 && <UserDocuments user={newUser} id={id}></UserDocuments>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser