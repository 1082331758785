import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { catalan_calendar } from '../../utils/editDatePicker'
import { Calendar } from "react-multi-date-picker"
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'
import getAllSeguiment from '../../services/seguiments/getAllSeguiment'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import getAllProfessional from '../../services/professionals/getAllProfessional'
import desactiveSeguiment from '../../services/seguiments/desactiveSeguiment'

import styles from './Seguiments.module.css'

const Seguiments = () => {

  const dateFormat = 'D/M/YYYY'
  const componentRef = useRef()

  const [ pageSize, setPageSize ] = useState(20)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ numberOfPages, setNumberOfPages ] = useState(0)
  const [ currentDataDisplayed, setCurrentDataDisplayed ] = useState([])
  const [ previousAllowed, setPreviousAllowed ] = useState(false)
  const [ nextAllowed, setNextAllowed ] = useState(true)
  const [ isChecked, setIsChecked ] = useState([])
  
  const [ seguiments, setSeguiments ] = useState([])
  const [ seguimentsFiltered, setSeguimentsFiltered ] = useState([])
  const [ seguimentsFiltered1, setSeguimentsFiltered1 ] = useState([])
  const [ seguimentsFiltered2, setSeguimentsFiltered2 ] = useState([])
  const [ seguimentsFiltered3, setSeguimentsFiltered3 ] = useState([])
  const [ seguimentsFiltered4, setSeguimentsFiltered4 ] = useState([])
  const [ seguimentsFiltered5, setSeguimentsFiltered5 ] = useState([])
  const [ seguimentsFiltered6, setSeguimentsFiltered6 ] = useState([])
  const [ professionals, setProfessionals ] = useState([])
  const [ users, setUsers ] = useState([])
  const [ headquarters, setHeadquarters ] = useState([])

  const [ visible, setVisible ] = useState(false)
  
  const [ update, setUpdate ] = useState(false)

  useEffect(() => {
    getAllSeguiment()
    .then(seguiments => {
      const seguimentsProfessional = seguiments.filter(element => element.seguimentProfessionalId._id === LocalStorage.getItem('id'))
      const today = new Date().toDateString()
      const seguimentsToday = seguiments.filter(element => new Date(element.seguimentPlanningDate).toDateString() === today)
      setSeguiments(seguiments)
      setSeguimentsFiltered(seguiments)
      setSeguimentsFiltered1(seguiments)
      setSeguimentsFiltered2(seguimentsProfessional)
      setSeguimentsFiltered3(seguiments)
      setSeguimentsFiltered4(seguiments)
      setSeguimentsFiltered6(seguiments)
      setSeguimentsFiltered5(seguimentsToday)
    })
    getAllHeadquarters()
    .then(arees => {
      setHeadquarters(arees)
    })
    getAllProfessional()
    .then(professionals => {
      setProfessionals(professionals)
    })
    getAllUsers()
    .then(users => {
      setUsers(users)
    })
  },[update])

  useEffect(() => {
    seguimentsFiltered &&
      setCurrentDataDisplayed(() => {
        const page = seguimentsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return page
      })
      setIsChecked(() => {
        const page = seguimentsFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return (new Array(page.length).fill(false))
      })
      setPreviousAllowed(() => currentPage > 1)
      setNextAllowed(() => currentPage < numberOfPages)
      setNumberOfPages(Math.ceil(seguimentsFiltered.length / pageSize))
  }, [currentPage, seguimentsFiltered, pageSize, update, numberOfPages])


  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(seguimentsFiltered1, seguimentsFiltered2, seguimentsFiltered3, seguimentsFiltered4, seguimentsFiltered5, seguimentsFiltered6)
    setSeguimentsFiltered(uniqueValues)
  }, [seguimentsFiltered1, seguimentsFiltered2,seguimentsFiltered3, seguimentsFiltered4, seguimentsFiltered5, seguimentsFiltered6])

  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return
      setCurrentPage((prevState) => (prevState -= 1))
    }
    if (action === "next") {
      if (!nextAllowed) return
      setCurrentPage((prevState) => (prevState += 1))
    }
  }

  const handleDesactiveMultiple = (event) => {
    const arrayId = []
    for (let index = 0; index < isChecked.length; index++) {
      arrayId[index] = currentDataDisplayed[index]._id
    }
    for (let index = 0; index < isChecked.length; index++) {
      isChecked[index] && desactiveSeguiment(arrayId[index])
    }
    setUpdate(!update)
  }

  // Función para canviar la paginación de la tabla
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value))
  }

  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value))
    })
  }

  const handleDesactive = (event) => {
    desactiveSeguiment(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula de seguiments",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "", "Planificat", "Realitzat", "Seguiment", "Usuari", "Professional", "Estat", "Situació", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por estat
  const handleChangeState = (value) => {
    if (value.length !== 0) {
      let seguimentsFiltered = seguiments.filter(element => value.includes(element.seguimentStatus))
      setSeguimentsFiltered1(seguimentsFiltered)
    } else {
      setSeguimentsFiltered1(seguiments)
    }
  }

  // Placeholder del filtro de estat
  const placeholderStatus = "Filtre situació"

  // Array con los elementos del filtro de estat
  const dataStatus = [
    {
      label: "Pendent",
      value: true,
    },
    {
      label: "Realitzat",
      value: false,
    },
  ]

  // Función para hacer funcionar el filtro de elementos => por professional
  const handleChangeProfessional = (value) => {
    if (value.length !== 0) {
      let seguimentsFiltered = seguiments.filter(element =>  value.includes(element?.seguimentProfessionalId?._id))
      setSeguimentsFiltered2(seguimentsFiltered)
    } else {
      setSeguimentsFiltered2(seguiments)
    }
  }

  // Placeholder del filtro de professional
  const placeholderFilterProfessionals = "Filtre professional"

  // Array con los elementos del filtro de professional
  const dataProfessionals = professionals && professionals.map(element => {
    return {
      label: `${element.professionalName} ${element.professionalLastName1} ${element.professionalLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por usuari
  const handleChangeUser = (value) => {
    if (value.length !== 0) {
      let usersFiltered = seguiments.filter(element => value.includes(element.seguimentUserId._id))
      usersFiltered.sort((a, b) => a.seguimentUserId.userLastName1.localeCompare(b.seguimentUserId.userLastName1))
      setSeguimentsFiltered3(usersFiltered)
    } else {
      setSeguimentsFiltered3(seguiments)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterUsers = "Filtre usuari"

  // Array con los elementos del filtro de usuaris
  const dataUsers = users && users.map(element => {
    return {
      label: `${element.userLastName1} ${element.userLastName2}, ${element.userName}`,
      value: element._id,
    }
  }) 

  // Función para hacer funcionar el filtro de elementos => por headquarters
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      let areaFiltered = seguiments.filter(element => value.includes(element.seguimentUserId.userAreaId)) 
      setSeguimentsFiltered4(areaFiltered)
    } else {
      setSeguimentsFiltered4(seguiments)
    }
  }

  // Placeholder del filtro de headquarters
  const placeholderFilterHeadquarters = "Filtre àrea"

  // Array con los elementos del filtro de headquarters
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por situacio
  const handleChangeSituacio = (value) => {
    if (value.length !== 0) {
      let situacioFiltered = seguiments.filter(element => element.seguimentPlanningStatus && value.includes(element.seguimentPlanningStatus))
      setSeguimentsFiltered6(situacioFiltered)
    } else {
      setSeguimentsFiltered6(seguiments)
    }
  }

  // Placeholder del filtro de headquarters
  const placeholderFilterSituacio = "Filtre estat seguiment"

  // Array con los elementos del filtro de headquarters
  const dataSituacio = [
    {
      label: "Pendent",
      value: "0",
    },
    {
      label: "Presentat",
      value: "1",
    },
    {
      label: "No presentat",
      value: "2",
    },
    {
      label: "Cancel·lat per família",
      value: "3",
    },
    {
      label: "Cancel·lat per Espai Viu",
      value: "4",
    },
  ]

  // Función para hacer funcionar el filtro de elementos => por data
  const handleChangeDate = (dateString) => {
    const date = new Date(dateString).toDateString()
    const value = [date]
    if (value[0] !== 'Thu Jan 01 1970') {
      let dateFiltered = seguiments.filter(element => {
        const date = new Date(element.seguimentPlanningDate).toDateString()
        return value.includes(date)
      }) 
      setSeguimentsFiltered5(dateFiltered)
    } else {
      setSeguimentsFiltered5(seguiments)
    }
  }

  const handelSelectedSeguiments = (position) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? !item : item
    )
    setIsChecked(updatedCheckedState)
  }
  
  const handelSelectedAllSeguiments = (event) => {
    const newValues = []
    if (event.target.checked === true) {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = true
      }
    } else {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = false
      }
    }
    setIsChecked(newValues)
  }

  const professionalSelect = LocalStorage.getItem('id')
  const today = new Date().toLocaleDateString()
  
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataUsers} 
              handleChange={handleChangeUser}
              placeholderFilter={placeholderFilterUsers}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataProfessionals} 
              handleChange={handleChangeProfessional}
              placeholderFilter={placeholderFilterProfessionals}
              value={professionalSelect}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataStatus} 
              handleChange={handleChangeState}
              placeholderFilter={placeholderStatus}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataHeadquarters} 
              handleChange={handleChangeHeadquarter}
              placeholderFilter={placeholderFilterHeadquarters}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <DatePicker 
              onChange={handleChangeDate} 
              className={styles.datapicker} 
              placeholder='Filtre data planificació'
              defaultValue={dayjs(today, dateFormat)}
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataSituacio} 
              handleChange={handleChangeSituacio}
              placeholderFilter={placeholderFilterSituacio}
              type='multiple'
            />
          </div>
        </div>
        <div className={visible === true ? styles.multipleCalendar : styles.noactive} >
          <Calendar 
            className={styles.calendar}
            numberOfMonths={5}
            locale={catalan_calendar}
            weekStartDayIndex={1}
            displayWeekNumbers="enable"
            range
            mapDays={({ date }) => {
              let props = {}
              const dateCalendar = new Date(date.unix * 1000).toDateString()
              if (seguimentsFiltered) {
                const dateArray = seguimentsFiltered.map(element => {
                    const data = new Date(element.seguimentPlanningDate)
                    return data.toDateString()
                  })
                const result = {}
                dateArray.forEach((value) => {
                  result[value] = (result[value] || 0) + 1
                })
                for (let index = 0; index < Object.keys(result).length; index++) {
                  const data = Object.keys(result)
                  const dataString = new Date(data[index]).toDateString()
                  const value = Object.values(result)
                  const valueString = value[index]
                  if (dateCalendar === dataString) {
                    if (valueString <= 3) {
                      props.style = {
                        backgroundColor: "#57cc99",
                      }
                    } else if (valueString > 3 && valueString <= 5 ) {
                      props.style = {
                        backgroundColor: "#ffba08",
                      }
                    } else {
                      props.style = {
                        backgroundColor: "#dc2f02",
                      }
                    }
                  }
                }
              }
              return props
            }}
          />        
        </div>
        <div className={visible === true ? styles.info : styles.noactive}>
          <span><i className={"fa-solid fa-circle"} id={styles.info1}></i> &#60; 3 seguiments</span>
          <span><i className={"fa-solid fa-circle"} id={styles.info2}></i> Entre 3 i 6 seguiments</span>
          <span><i className={"fa-solid fa-circle"} id={styles.info3}></i> &#62; 6 seguiments</span>
        </div>
        <div className={styles.button}>
          <div className={styles.pagination}>
            <button onClick={() => handlePagination("prev")}><i className="fa-solid fa-backward"></i></button>
            <button onClick={() => handlePagination("next")}><i className="fa-solid fa-forward"></i></button>
            <p>
              <span>{pageSize * (currentPage - 1)}</span> a <span>{currentDataDisplayed && currentDataDisplayed.length+(currentPage - 1) * pageSize}</span> de <span>{seguimentsFiltered?.length}</span> resultats
            </p>
            <div className={styles.view}>
              <span>Mostrar </span>
              <select onChange={handleChangePageSize} value={pageSize}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> resultats</span>
            </div>
          </div>
          <div className={styles.buttons}>
              <Button handleOnClick={() => setVisible(!visible)} icon={visible === false ? "fa-calendar-plus" : "fa-calendar-minus"}/>
              <Button path="/seguiments/seguiments-calendar" icon={"fa-calendar-days"}/>
              <Button handleOnClick={handlePrint} icon={"fa-print"}/>
              <Button handleOnClick={handleDesactiveMultiple} icon={"fa-trash"}/>
              <Button path="/seguiments/new-seguiment" icon={"fa-circle-plus"}/>
            </div>
          </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return index === 0 ? 
                    <th className={styles.columns} key={index}><input type='checkbox' onChange={handelSelectedAllSeguiments}></input></th>  :
                    <th className={styles.columns} key={index}>{element}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                currentDataDisplayed && currentDataDisplayed.map((element, index) => {
                  let url = '/seguiments/' + element._id
                  let user = '/usuaris/fitxa-usuari/' + element.seguimentUserId._id
                  let checkedItem = isChecked[index]
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>
                          <input type='checkbox' id={index} value={element._id} checked={checkedItem} onChange={() => handelSelectedSeguiments(index)}></input>
                      </td>
                      <td className={styles.columns}>{new Date(element.seguimentPlanningDate).toLocaleDateString()}</td>
                      <td className={styles.columns}>{element.seguimentDoDate && new Date(element.seguimentDoDate).toLocaleDateString()}</td>
                      <td className={styles.columns}><Link to={url}>{element.seguimentType.seguimentTypeName}</Link></td>
                      <td className={styles.columns}><Link to={user}>{element.seguimentUserId.userName} {element.seguimentUserId.userLastName1} {element.seguimentUserId.userLastName2}</Link></td>
                      <td className={styles.columns}>{element.seguimentProfessionalId.professionalName} {element.seguimentProfessionalId.professionalLastName1} {element.seguimentProfessionalId.professionalLastName2}</td>
                      <td className={styles.columns}>
                        {
                          element.seguimentPlanningStatus === '0' ? "Pendent" :
                          element.seguimentPlanningStatus === '1' ? "Presentat" :
                          element.seguimentPlanningStatus === '2' ? "No presentat" :
                          element.seguimentPlanningStatus === '3' ? "Cancel·lada per família" :
                          element.seguimentPlanningStatus === '4' ? "Cancel·lada per Espai Viu" : ""
                        }
                      </td>
                      <td className={styles.columns}>{element.seguimentStatus === true ? "Pendent" : "Realitzat"}</td>
                      <td className={styles.columns}>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Seguiments
