import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import Button from '../../components/UI/Button'
import Filter from '../../components/Filter/Filter'

import getAllUsers from '../../services/users/getAllUsers'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import desactiveUser from '../../services/users/desactiveUser'

import styles from './Users.module.css'

const Users = (props) => {

  const componentRef = useRef()

  const [ pageSize, setPageSize ] = useState(20)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ numberOfPages, setNumberOfPages ] = useState(0)
  const [ currentDataDisplayed, setCurrentDataDisplayed ] = useState([])
  const [ previousAllowed, setPreviousAllowed ] = useState(false)
  const [ nextAllowed, setNextAllowed ] = useState(true)
  const [ isChecked, setIsChecked ] = useState([])
  
  const [ users, setUsers ] = useState([])
  const [ usersFiltered, setUsersFiltered ] = useState([])
  const [ usersFiltered1, setUsersFiltered1 ] = useState([])
  const [ usersFiltered2, setUsersFiltered2 ] = useState([])
  const [ headquarters, setHeadquarters ] = useState([])
  const [ update, setUpdate ] = useState(false)
  
  useEffect(() => {
    getAllUsers()
    .then(elements => {
      setUsers(elements)
      setUsersFiltered(elements)
      setUsersFiltered1(elements)
      setUsersFiltered2(elements)
    })
    getAllHeadquarters()
    .then(arees => {
      setHeadquarters(arees)
    })
  },[])

  useEffect(() => {
    usersFiltered &&
      setCurrentDataDisplayed(() => {
        const page = usersFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return page
      })
      setIsChecked(() => {
        const page = usersFiltered?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return (new Array(page.length).fill(false))
      })
      setPreviousAllowed(() => currentPage > 1)
      setNextAllowed(() => currentPage < numberOfPages)
      setNumberOfPages(Math.ceil(usersFiltered.length / pageSize))
  }, [currentPage, usersFiltered, pageSize, update, numberOfPages])

  // Actualizar els seguimients filtrats combinant ambdos filtres
  useEffect(() => {
    const uniqueValues = getCommonValues(usersFiltered1, usersFiltered2)
    setUsersFiltered(uniqueValues)
  }, [usersFiltered1, usersFiltered2])

  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return
      setCurrentPage((prevState) => (prevState -= 1))
    }
    if (action === "next") {
      if (!nextAllowed) return
      setCurrentPage((prevState) => (prevState += 1))
    }
  }

  const handleDesactiveMultiple = (event) => {
    const arrayId = []
    for (let index = 0; index < isChecked.length; index++) {
      arrayId[index] = currentDataDisplayed[index]._id
    }
    for (let index = 0; index < isChecked.length; index++) {
      isChecked[index] && desactiveUser(arrayId[index])
    }
    setUpdate(!update)
  }

  function getCommonValues(...arrays) {
    return arrays.reduce((acc, currentArray) => {
      return acc.filter((value) => currentArray.includes(value))
    })
  }

  const handleDesactive = (event) => {
    desactiveUser(event.target.parentNode.parentNode.id)
    setUpdate(!update)
  }

  // Función para canviar la paginación de la tabla
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value))
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Taula de usuaris",
    onafterprint: () => alert('Print success')
  })
  
  const titles = [ "", "Usuari", "Àrea", "Estat", "Accions" ]

  // Función para hacer funcionar el filtro de elementos => por usuari
  const handleChangeUser = (value) => {
    if (value.length !== 0) {
      let usersFiltered = users && users.filter(element =>  value.includes(element._id)) 
      setUsersFiltered1(usersFiltered)
    } else {
      setUsersFiltered1(users)
    }
  }

  // Placeholder del filtro de usuaris
  const placeholderFilterUsers = "Filtre usuari"

  // Array con los elementos del filtro de usuaris
  const dataUsers = users && users.map(element => {
    return {
      label: `${element.userName} ${element.userLastName1} ${element.userLastName2}`,
      value: element._id
    }
  })

  // Función para hacer funcionar el filtro de elementos => por headquarters
  const handleChangeHeadquarter = (value) => {
    if (value.length !== 0) {
      let userFiltered = users && users.filter(element => value.includes(element.userAreaId._id)) 
      setUsersFiltered2(userFiltered)
    } else {
      setUsersFiltered2(users)
    }
  }

  // Placeholder del filtro de headquarters
  const placeholderFilterHeadquarters = "Filtre àrea"

  // Array con los elementos del filtro de headquarters
  const dataHeadquarters = headquarters.map(element => {
    return {
      label: `${element.areaName}`,
      value: element._id
    }
  })

  const handelSelectedUsers = (position) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? !item : item
    )
    setIsChecked(updatedCheckedState)
  }
  
  const handelSelectedAllUsers = (event) => {
    const newValues = []
    if (event.target.checked === true) {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = true
      }
    } else {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = false
      }
    }
    setIsChecked(newValues)
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.filters}>
          <div className={styles.multiselector}>
            <Filter
              data={dataUsers} 
              handleChange={handleChangeUser}
              placeholderFilter={placeholderFilterUsers}
              type='multiple'
            />
          </div>
          <div className={styles.multiselector}>
            <Filter
              data={dataHeadquarters} 
              handleChange={handleChangeHeadquarter}
              placeholderFilter={placeholderFilterHeadquarters}
              type='multiple'
            />
          </div>
        </div>
        <div className={styles.button}>
          <div className={styles.pagination}>
            <button onClick={() => handlePagination("prev")}><i className="fa-solid fa-backward"></i></button>
            <button onClick={() => handlePagination("next")}><i className="fa-solid fa-forward"></i></button>
            <p>
              <span>{pageSize * (currentPage - 1)}</span> a <span>{currentDataDisplayed && currentDataDisplayed.length+(currentPage - 1) * pageSize}</span> de <span>{usersFiltered?.length}</span> resultats
            </p>
            <div className={styles.view}>
              <span>Mostrar </span>
              <select onChange={handleChangePageSize} value={pageSize}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> resultats</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button handleOnClick={handlePrint} icon={"fa-print"}/>
            <Button handleOnClick={handleDesactiveMultiple} icon={"fa-trash"}/>
            <Button path="/usuaris/new-usuari" icon={"fa-circle-plus"}/>
          </div>
        </div>
      </div>
      <div className={styles.listElements} ref={componentRef}>
        <div className={`${styles.table} ${styles.ver3}`}>
          <table data-vertable="ver3" className={styles.etable}>
            <thead>
              <tr className={`${styles.rows} ${styles.head}`}>
                {
                  titles.map((element, index) =>{
                    return index === 0 ?
                        <th className={styles.columns} key={index}><input type='checkbox' onChange={handelSelectedAllUsers}></input></th>  :
                        <th className={styles.columns} key={index}>{element}</th>
                    })
                }
              </tr>
            </thead>
            <tbody>
              {
                currentDataDisplayed && currentDataDisplayed.map((element, index) => {
                  let url = '/usuaris/' + element._id
                  let user = '/usuaris/fitxa-usuari/' + element._id
                  let checkedItem = isChecked[index]
                  return (
                    <tr className={styles.rows} id={element._id} key={element._id}>
                      <td className={styles.columns}>
                          <input type='checkbox' id={index} value={element._id} checked={checkedItem} onChange={() => handelSelectedUsers(index)}></input>
                      </td>
                      <td className={styles.columns}><Link to={user}>{element.userLastName1} {element.userLastName2}, {element.userName}</Link></td>
                      <td className={styles.columns}>{element.userAreaId.areaName}</td>
                      <td className={styles.columns}>{element.userActive ? "Actiu" : "No actiu"}</td>
                      <td className={styles.columns}>
                        <Link to={user}><i className="fa-solid fa-eye"></i></Link>
                        <Link to={url}><i className="fa-solid fa-pen-to-square"></i></Link>
                        {
                          props.professionalProfile === "Administrador" && <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
                        }
                      </td>
                    </tr>  
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Users
