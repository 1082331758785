import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { catalan_calendar } from '../../utils/editDatePicker'
import DatePicker from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker"
import Modal from '../../components/Modal/Modal'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getAllUsers from '../../services/users/getAllUsers'
import getAllProfessionals from '../../services/professionals/getAllProfessional'
import getAllSeguimentType from '../../services/seguimentType/getAllSeguimentType'
import postSeguiment from '../../services/seguiments/postSeguiment'

import styles from './NewSeguiment.module.css'

const NewSeguiment = () => {

    let navigate = useNavigate()

    const [ newSeguiment, setNewSeguiment ] = useState({})
    const [ users, setUsers ] = useState([])
    const [ professionals, setProfessionals ] = useState([])
    const [ seguimentTypes, setSeguimentTypes ] = useState([])
    const [ headquarters, setHeadquarters ] = useState([])
    const [ values, setValues ] = useState([])
    const [ time, setTime ] = useState(0)
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)

    useEffect(() => {
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
        getAllUsers()
        .then(users => {
            users.sort((a, b) => a.userLastName1.localeCompare(b.userLastName1))
            setUsers(users)
        })
        getAllProfessionals()
        .then(professionals => {
            professionals.sort((a, b) => a.professionalLastName1.localeCompare(b.professionalLastName1))
            setProfessionals(professionals)
        })
        getAllSeguimentType()
        .then(seguimentType => {
            setSeguimentTypes(seguimentType)
        })
    },[])

    const handleSubmit = (event) => {
        event.preventDefault()
        for (let index = 0; index < values.length; index++) {
            const form = event.target
            const formData = new FormData(form)
            const formJson = Object.fromEntries(formData.entries())
            let dateUnixPlanning = new Date(values[index].unix * 1000).toISOString()
            formJson.seguimentPlanningDate = dateUnixPlanning
            formJson.seguimentStatus = false
            postSeguiment(formJson) 
            .then(newSeguiment => {
                setNewSeguiment(newSeguiment);
                setDisable(false)
                setTimeout(() => {
                    setDisable(true)
                    navigate("/seguiments", { replace: true })
                },3000)
            })
        }
    }

    const handleChange = (event) => {
        setNewSeguiment(event.target.value)
    }

    return (
        <div className={styles.container}>
            <h2>Planificar Seguiment</h2>
            <div className={disable && styles.disable}>
                <Modal error={error} text="Seguiment/s creat/s correctament"></Modal>
            </div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="seguimentUserId">Usuari</label>
                <select onChange={handleChange} name="seguimentUserId" value={newSeguiment.seguimentUserId} id="seguimentUserId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        users.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.userLastName1} {element.userLastName2}, {element.userName} </option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentProfessionalId">Professional</label>
                <select onChange={handleChange} name="seguimentProfessionalId" value={newSeguiment.seguimentProfessionalId} id="seguimentProfessionalId">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        professionals.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.professionalLastName1} {element.professionalLastName2}, {element.professionalName}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentType">Tipus seguiment</label>
                <select onChange={handleChange} name="seguimentType" value={newSeguiment.seguimentType} id="seguimentType">
                    <option value="selecciona">- Selecciona -</option>
                    {
                        seguimentTypes.map( element => {
                            return (
                                <option key={element._id} value={element._id}>{element.seguimentTypeName}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="seguimentPlanningTime">Durada</label>
                <select onChange={handleChange} name="seguimentPlanningTime" value={newSeguiment.seguimentPlanningTime} id="seguimentPlanningTime">
                    <option value="00">- Selecciona -</option>
                    <option value="30">0h 30'</option>
                    <option value="45">0h 45'</option>
                    <option value="60">1h 00'</option>
                    <option value="90">1h 30'</option>
                    <option value="180">2h 00'</option>
                </select>
                <label htmlFor="seguimentPlanningDate">Dates</label>
                <DatePicker
                    plugins={[
                        <TimePicker />
                    ]} 
                    style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: "100%",
                        padding: "0.5em",
                        backgroundColor: "aliceblue",
                        borderRadius: ".3rem",
                        border: "none",
                    }}
                    containerStyle={{
                        width: "100%"
                    }}
                    calendarPosition="bottom-center" 
                    locale={catalan_calendar}
                    displayWeekNumbers="enable"
                    numberOfMonths={3}
                    weekStartDayIndex={1}
                    multiple 
                    value={values} 
                    format="DD/MM/YYYY HH:mm"
                    onChange={setValues} 
                />
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>Crear</button>
                </div>
            </form>
        </div>
    )
}

export default NewSeguiment