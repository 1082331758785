import React, { useEffect, useState } from 'react'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'

import Modal from '../../components/Modal/Modal'
import postDocumentsUser from '../../services/documentsUser/getAllDocumentsUsersByUserId'
import desactiveDocumentsUser from '../../services/documentsUser/desactiveDocumentsUser'
import postDocument from '../../services/documents/postDocument'
import postDocumentUser from '../../services/documentsUser/postDocumentsUser'
import getPresignedUrl from '../../services/documents/getPresignedUrl'

import styles from './UserDocuments.module.css'

const UserDocuments = ({ user, id }) => {

  const [ pageSize, setPageSize ] = useState(20)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ numberOfPages, setNumberOfPages ] = useState(0)
  const [ currentDataDisplayed, setCurrentDataDisplayed ] = useState([])
  const [ previousAllowed, setPreviousAllowed ] = useState(false)
  const [ nextAllowed, setNextAllowed ] = useState(true)
  const [ isChecked, setIsChecked ] = useState([])

  const [ documents, setDocuments ] = useState([])
  const [ documentsFilter, setDocumentsFilter ] = useState([])
  const [ update, setUpdate ] = useState(false)
  const [ disable, setDisable ] = useState(true)

  const [ error, setError ] = useState("")
  const [ file, setFile ] = useState([])

  useEffect(() => {
    postDocumentsUser(user._id)
      .then(data => {
        setDocuments(data)
        setDocumentsFilter(data)
      })
  }, [user._id, update])

  useEffect(() => {
    documentsFilter &&
      setCurrentDataDisplayed(() => {
        const page = documentsFilter?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return page
      })
      setIsChecked(() => {
        const page = documentsFilter?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        return (new Array(page.length).fill(false))
      })
      setPreviousAllowed(() => currentPage > 1)
      setNextAllowed(() => currentPage < numberOfPages)
      setNumberOfPages(Math.ceil(documentsFilter.length / pageSize))
  }, [currentPage, documentsFilter, pageSize, update, numberOfPages])

  const handlePagination = (action) => {
    if (action === "prev") {
      if (!previousAllowed) return
      setCurrentPage((prevState) => (prevState -= 1))
    }
    if (action === "next") {
      if (!nextAllowed) return
      setCurrentPage((prevState) => (prevState += 1))
    }
  }

  // Función para canviar la paginación de la tabla
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value))
  }

  const handleDesactive = (event) => {
    desactiveDocumentsUser(event.target.parentNode.parentNode.id)
    .then(() => {
      setUpdate(!update)
    })
    setDisable(false)
    setTimeout(() => {
      setDisable(true)
    },3000)
  }

  const handelSelectedUsers = (position) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? !item : item
    )
    setIsChecked(updatedCheckedState)
  }
      
  const handelSelectedAllUsers = (event) => {
    const newValues = []
    if (event.target.checked === true) {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = true
      }
    } else {
      for (let index = 0; index < isChecked.length; index++) {
        newValues[index] = false
      }
    }
    setIsChecked(newValues)
  }

  // Funció per normalitzar el nom del fitxer
  const normalizeFilename = (filename) => {
    // Normaliza el text eliminant els accents
    filename = filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    // Reemplaza espais per guions baixos i converteix a minúscules
    filename = filename.replace(/\s+/g, "_").toLowerCase()
    return filename
  }

  const titles = ["", "Document", "Professional", "Data i hora", "Acció"]

  const controlUpload = async (event) => {
    event.preventDefault()
    if(file.length === 0) {
      setError("Selecciona un document")
    } else {
      const formData = new FormData()
      formData.append('file', file)
      postDocument(formData, id)
      .then((res) => {
        const normalizeName = normalizeFilename(res.documentName)
        const formDataDocument = new FormData()
        formDataDocument.append('documentUserUrl', res.url)
        formDataDocument.append('documentUserUserId', id)
        formDataDocument.append('documentUserName', normalizeName)
        formDataDocument.append('documentUserCompanyId', LocalStorage.getItem("professionalCompanyId"))
        formDataDocument.append('documentUserProfessionalId', LocalStorage.getItem("id"))
        const formJson = Object.fromEntries(formDataDocument.entries())
        postDocumentUser(formJson)
        .then(() => {
          setUpdate(!update)
        })
        setDisable(false)
        setFile([])
        setTimeout(() => {
          setDisable(true)
        },3000)
      })
    }
  }
    
  const controlCanvis = (event) => {
    const file = event.target.files[0]
    if (file) {
      const fileName = file.name.toLowerCase()
      if (fileName.endsWith('.pdf') || fileName.endsWith('.doc') || fileName.endsWith('.docx') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx') || fileName.endsWith('.ppt')|| fileName.endsWith('.pptx') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png')) {
        if (file.size > 10000000) { // 10MB limit
          setError("El document supera el límit de tamany 10MB")
          return
        }
        setFile(file)
        setError("")
        setUpdate(!update)
      } else {
        setError("Format de document no vàlid")
      }
    }
  }

  const deleteFile = () => {
    setFile([])
  }

  return (
    <div className={styles.container}>
      <div className={disable && styles.disable}>
        <Modal error={error} id={id} text={"Arxiu carregat correctament"}></Modal>
      </div>
      <div className={styles.main}>
      <div className={styles.button}>
        <div className={styles.pagination}>
        <button onClick={() => handlePagination("prev")}><i className="fa-solid fa-backward"></i></button>
        <button onClick={() => handlePagination("next")}><i className="fa-solid fa-forward"></i></button>
        <p>
          <span>{pageSize * (currentPage - 1)}</span> a <span>{currentDataDisplayed && currentDataDisplayed.length+(currentPage - 1) * pageSize}</span> de <span>{documentsFilter?.length}</span> resultats
        </p>
        <div className={styles.view}>
          <span>Mostrar </span>
          <select onChange={handleChangePageSize} value={pageSize}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          </select>
          <span> resultats</span>
        </div>
        </div>
        <div className={styles.buttons}>
          <form className={styles.uploadForm}>
            <span id="file-name" className={styles.spantext}>{file.length === 0 ? "Cap arxiu seleccionat (tamany max. 10MB)" : file.name} {file.length !== 0 && <i class="fa-regular fa-circle-xmark" onClick={deleteFile}></i>}</span>
            <label for="file-upload" className={styles.uploadButton1}><i class="fa-solid fa-magnifying-glass-plus"></i></label>
            <input type='file' onChange={controlCanvis} id="file-upload" className={styles.uploadInput}></input>
            <button type='submit' onClick={controlUpload} className={styles.uploadButton}><i class="fa-solid fa-cloud-arrow-up"></i></button>
          </form>
        </div>
      </div>
      </div>
      <div className={styles.listElements}>
      <div className={`${styles.table} ${styles.ver3}`}>
        <table data-vertable="ver3" className={styles.etable}>
        <thead>
          <tr className={`${styles.rows} ${styles.head}`}>
          {
            titles.map((element, index) =>{
            return index === 0 ?
              <th className={styles.columns} key={index}><input type='checkbox' onChange={handelSelectedAllUsers}></input></th>  :
              <th className={styles.columns} key={index}>{element}</th>
            })
          }
          </tr>
        </thead>
        <tbody>
          {
          currentDataDisplayed && currentDataDisplayed.map((element, index) => {
            const nameUrl = element.documentUserUrl
            const date = new Date(element.createdAt)
            let checkedItem = isChecked[index]
            
            return (
            <tr className={styles.rows} id={element._id} key={element._id}>
              <td className={styles.columns}>
                <input type='checkbox' id={index} value={element._id} checked={checkedItem} onChange={() => handelSelectedUsers(index)}></input>
              </td>
              <td className={styles.columns}>{element.documentUserName}</td>
              <td className={styles.columns}>{element.documentUserProfessionalId.professionalName} {element.documentUserProfessionalId.professionalLastName1} {element.documentUserProfessionalId.professionalLastName2}</td>
              <td className={styles.columns}>{date.toLocaleDateString()} {date.toLocaleTimeString()}</td>
              <td className={styles.columns}>
              <i class="fa-solid fa-file-lines" onClick={() => getPresignedUrl(nameUrl)}></i>
              <i className="fa-solid fa-trash" onClick={handleDesactive}></i>
              </td>
            </tr>  
            )
          })
          }
        </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}

export default UserDocuments
