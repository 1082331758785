import axios from 'axios'
import { LocalStorage } from '../localStorage/LocalStorage.services'

const postDocument = async (data, id) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/document`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'auth-token': LocalStorage.getItem("token"),
                'id': id
            }
        })
        if (response.status === 400 || response.status === 401) {
            localStorage.clear() // Netejar localStorage
            window.location.href = "/login" // Redirigir al login
            return {message: "Unauthorized"}
        } else if (response.status === 200){
            return  response.data
        }
    } catch (error) {
        return({
            message: error.message || 'Something went wrong!'
        })
    }
}

export default postDocument