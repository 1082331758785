import { LocalStorage } from '../localStorage/LocalStorage.services'

const getAllDocumentsUsersByUserId = async (data) => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/documents-user`,
            {
                headers: {
                    'auth-token': LocalStorage.getItem("token"),
                    'userId': data
                },
            }
        )
        
        if (response.status === 400 || response.status === 401) {
            localStorage.clear() // Netejar localStorage
            window.location.href = "/login" // Redirigir al login
            return {message: "Unauthorized"}
        } else if (response.ok){
            const data = await response.json()
            const dataNoDeleted = data.filter(element => {
                return element.documentUserDeleted === false
            })
            return  dataNoDeleted
        }
    } catch (error) {
        return {message: error.message || "Something went wrong"}
    }
}
    
export default getAllDocumentsUsersByUserId