import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Select from 'react-select'
import Modal from '../../components/Modal/Modal'
import moment from 'moment'
import PdfAltaUser from '../Pdf/AltaUserPdf'
import PdfBaixaUser from '../Pdf/BaixaUserPdf'

import getAllPayType from '../../services/payType/getAllPayType'
import getAllHeadquarters from '../../services/headquartes/getAllHeadquarters'
import getUserById from '../../services/users/getUserById'
import postUser from '../../services/users/postUser'
import putUser from '../../services/users/putUser'
import getAllMunicipis from '../../services/municipis/getAllMunicipis'
// import sendMail from '../../services/mail/postMail'

import styles from './NewUser.module.css'

const NewUser = () => {

    let navigate = useNavigate()

    const { id } = useParams()
    const [ newUser, setNewUser ] = useState({})
    const [ active, setActive ] = useState(true)
    const [ headquarters, setHeadquarters ] = useState([])
    const [ payType, setPayType ] = useState([])
    const [ error, setError ] = useState("")
    const [ disable, setDisable ] = useState(true)
    const [ municipis, setMunicipis ] = useState([])

    // Càlcul d'anys
    let naixement = moment(newUser.userDataNaixement)
    let avui = moment()
    let anys = avui.diff(naixement,"years")

    useEffect(() => {
        getAllPayType()
        .then(payType => {
            setPayType(payType)
        })
        getAllHeadquarters()
        .then(headquarters => {
            setHeadquarters(headquarters)
        })
        if (id !== undefined) {
            getUserById(id)
            .then(user => {
                setNewUser(user)
                setActive(user.userActive)
            })
        }
        getAllMunicipis()
        .then(municipis => {
            municipis.sort((a,b) => a.nom_municipi < b.nom_municipi ? -1 : +(a.nom_municipi > b.nom_municipi))
            setMunicipis(municipis)
        })
    },[id])

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        formData.append('userActive', event.target.userActive.checked)
        formData.append('userCompanyId', LocalStorage.getItem("professionalCompanyId"))
        formData.append('userAreaId', LocalStorage.getItem("professionalAreaId"))
        const formJson = Object.fromEntries(formData.entries())
        if (formJson.userSistemaPagament === "") {
            delete formJson.userSistemaPagament
        }
        if (id !== undefined) {
            putUser(formJson, id) 
            .then(newUser => {
                setNewUser(newUser)
                // sendMail(userMail)
            })
        } else {
            postUser(formJson) 
            .then(newUser => {
                setNewUser(newUser)
            })
        }
        setDisable(false)
        setTimeout(() => {
            setDisable(true)
            navigate("/usuaris", { replace: true })
        },3000)
    }

    const handleChangeCheck = (event) => {
        if (event.target.id === "userActive") {
            setActive(event.target.checked)
        } else {
            setNewUser(event.target.value)
        }
    }

    const handleChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setNewUser(prevState => ({
            ...prevState,
            [name]: selectedOption ? selectedOption.value : ''
        }))
    }

    const sexeOptions = [
        { value: 'Nena', label: 'Nena' },
        { value: 'Nen', label: 'Nen' },
    ];

    const poblacioOptions = municipis.map(element => ({
        value: `(${element.codi_postal}) ${element.nom_municipi}`,
        label: `(${element.codi_postal}) ${element.nom_municipi}`
    }))

    const parentestOptions = [
        { value: 'Mare', label: 'Mare' },
        { value: 'Pare', label: 'Pare' },
        { value: 'Tutor/a', label: 'Tutor/a' },
        { value: 'Avia', label: 'Avia' },
        { value: 'Avi', label: 'Avi' },
    ];

    const tipusOptions = [
        { value: 'Avinguda', label: 'Avinguda' },
        { value: 'Barri', label: 'Barri' },
        { value: 'Camí', label: 'Camí' },
        { value: 'Carrer', label: 'Carrer' },
        { value: 'Carretera', label: 'Carretera' },
        { value: 'Plaça', label: 'Plaça' },
        { value: 'Passatge', label: 'Passatge' },
        { value: 'Passeig', label: 'Passeig' },
        { value: 'Ronda', label: 'Ronda' },
        { value: 'Rambla', label: 'Rambla' },
        { value: 'Urbanització', label: 'Urbanització' },
        { value: 'Altres', label: 'Altres' },
    ]

    const sistemaPagamentOptions = payType && payType.map(sistema => ({
        value: sistema._id,
        label: sistema.payTypeName
    }))

    return (
        <div className={styles.container}>
            <div className={disable && styles.disable}>
                <Modal error={error} id={id} text={id ? "Usuari modificat correctament" : "Usuari creat correctament"}></Modal>
            </div>
            <div className={styles.title}>
                <h2>{id ? "Editar Usuari" : "Alta nou Usuari"}</h2>
                <div className={styles.pdf}>
                    <div className={styles.pdf_container}>
                        <PDFDownloadLink
                            document={<PdfAltaUser user={newUser} />}
                            fileName={`Fitxa_${newUser.userName}_${newUser.userLastName1}_${newUser.userLastName2}.pdf`}
                        >
                            {({ loading }) =>
                                loading ? (
                                    <div className={styles.btn_pdf} disable><i class="fa-solid fa-file-lines"></i>ALTA USUARI</div>
                                ) : (
                                    <div className={styles.btn_pdf}><i class="fa-solid fa-file-lines"></i>ALTA USUARI</div>
                                )
                            }
                        </PDFDownloadLink>
                        {
                            (newUser.userDataBaixa && newUser.userDataBaixa !== "") ?
                            <PDFDownloadLink
                                document={<PdfBaixaUser user={newUser} />}
                                fileName={`Fitxa_${newUser.userName}_${newUser.userLastName1}_${newUser.userLastName2}.pdf`}
                            >
                                {({ loading }) =>
                                    loading ? (
                                        <div className={styles.btn_pdf} disable><i class="fa-solid fa-file-lines"></i>BAIXA USUARI</div>
                                    ) : (
                                        <div className={styles.btn_pdf}><i class="fa-solid fa-file-lines"></i>BAIXA USUARI</div>
                                    )
                                }
                            </PDFDownloadLink> : null
                        }
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <label for="userCode">Codi</label>
                <input type='text' onChange={handleChangeCheck} name="userCode" value={newUser.userCode}></input>
                <label for="userDataAlta">Data Alta</label>
                <input type='date' onChange={handleChangeCheck} name="userDataAlta" value={newUser.userDataAlta && moment(new Date(newUser.userDataAlta)).format('YYYY-MM-DD')}></input>
                <label for="userDataBaixa">Data Baixa</label>
                <input type='date' onChange={handleChangeCheck} name="userDataBaixa" value={newUser.userDataBaixa && moment(new Date(newUser.userDataBaixa)).format('YYYY-MM-DD')}></input>
                <label for="userMotiuConsulta">Motiu consulta</label>
                <input type='text' onChange={handleChangeCheck} name="userMotiuConsulta" value={newUser.userMotiuConsulta}></input>
                <label for="userName">Nom</label>
                <input type='text' onChange={handleChangeCheck} name="userName" value={newUser.userName}></input>
                <label for="userLastName1">Cognom 1</label>
                <input type='text' onChange={handleChangeCheck} name="userLastName1" value={newUser.userLastName1}></input>
                <label for="userLastName2">Cognom 2</label>
                <input type='text' onChange={handleChangeCheck} name="userLastName2" value={newUser.userLastName2}></input>
                <label for="userEmail">Email contacte</label>
                <input type='email' onChange={handleChangeCheck} name="userEmail" value={newUser.userEmail}></input>
                <label for="userDNI">DNI</label>
                <input type='text' onChange={handleChangeCheck} name="userDNI" value={newUser.userDNI}></input>
                <label for="userDataNaixement">Data Naixement</label>
                <input type='date' onChange={handleChangeCheck} name="userDataNaixement" value={newUser.userDataNaixement && moment(new Date(newUser.userDataNaixement)).format('YYYY-MM-DD')}></input>
                {
                    id && <>
                        <label>Edat</label>
                        <label>{anys} anys</label>
                    </> 
                }
                <label for="userSexe">Sexe</label>
                <Select
                    id='userSexe'
                    name="userSexe"
                    value={sexeOptions.find(option => option.value === newUser.userSexe)}
                    onChange={handleChange}
                    options={sexeOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userCertificatDiscapacitat">Certificat de Discapacitat</label>
                <select onChange={handleChangeCheck} name="userCertificatDiscapacitat" value={newUser.userCertificatDiscapacitat} id="userCertificatDiscapacitat" defaultValue={false}>
                    <option value={true}>Si</option>
                    <option value={false}>No</option>
                </select>
                <label for="userDiagnostic">Diagnostic</label>
                <input type='text' onChange={handleChangeCheck} name="userDiagnostic" value={newUser.userDiagnostic}></input>
                <label for="userTipus">Tipus Via</label>
                <Select
                    id='userTipus'
                    name="userTipus"
                    value={tipusOptions.find(option => option.value === newUser.userTipus)}
                    onChange={handleChange}
                    options={tipusOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userDireccio">Direcció</label>
                <input type='text' onChange={handleChangeCheck} name="userDireccio" value={newUser.userDireccio}></input>
                <label for="userPoblacio">Població</label>
                <Select
                    id='userPoblacio'
                    name="userPoblacio"
                    value={poblacioOptions.find(option => option.value === newUser.userPoblacio)}
                    onChange={handleChange}
                    options={poblacioOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userActive">Actiu</label>
                <input type='checkbox' onChange={handleChangeCheck} name="userActive" id="userActive" checked={active} className={styles.checking} />
                <label for="userEscola">Escola</label>
                <input type='text' onChange={handleChangeCheck} name="userEscola" value={newUser.userEscola}></input>
                <label for="userInformacio">Informació complementaria</label>
                <textarea onChange={handleChangeCheck} name="userInformacio" value={newUser.userInformacio}></textarea>
                <label for="userResponsableLegal1Name">Responsable legal 1 - Nom</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal1Name" value={newUser.userResponsableLegal1Name}></input>
                <label for="userResponsableLegal1LastName1">Responsable legal 1 - Cognom 1</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal1LastName1" value={newUser.userResponsableLegal1LastName1}></input>
                <label for="userResponsableLegal1LastName2">Responsable legal 1 - Cognom 2</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal1LastName2" value={newUser.userResponsableLegal1LastName1}></input>
                <label for="userResponsableLegal1Email">Responsable legal 1 - Email contacte</label>
                <input type='email' onChange={handleChangeCheck} name="userResponsableLegal1Email" value={newUser.userResponsableLegal1Email}></input>
                <label for="userResponsableLegal1Telefon">Responsable legal 1 - Telèfon contacte</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal1Telefon" value={newUser.userResponsableLegal1Telefon}></input>     
                <label for="userResponsableLegal1Parentesc">Responsable legal 1 - Parentesc</label>
                <Select
                    id='userResponsableLegal1Parentesc'
                    name="userResponsableLegal1Parentesc"
                    value={parentestOptions.find(option => option.value === newUser.userResponsableLegal1Parentesc)}
                    onChange={handleChange}
                    options={parentestOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userResponsableLegal2Name">Responsable legal 2 - Nom</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal2Name" value={newUser.userResponsableLegal2Name}></input>
                <label for="userResponsableLegal2LastName1">Responsable legal 2 - Cognom 1</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal2LastName1" value={newUser.userResponsableLegal2LastName1}></input>
                <label for="userResponsableLegal2LastName2">Responsable legal 2 - Cognom 2</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal2LastName2" value={newUser.userResponsableLegal2LastName2}></input>
                <label for="userResponsableLegal2Email">Responsable legal 2 - Email contacte</label>
                <input type='email' onChange={handleChangeCheck} name="userResponsableLegal2Email" value={newUser.userResponsableLegal2Email}></input>
                <label for="userResponsableLegal2Telefon">Responsable legal 2 - Telèfon contacte</label>
                <input type='text' onChange={handleChangeCheck} name="userResponsableLegal2Telefon" value={newUser.userResponsableLegal2Telefon}></input>
                <label for="userResponsableLegal2Parentesc">Responsable legal 2 - Parentesc</label>
                <Select
                    id='userResponsableLegal2Parentesc'
                    name="userResponsableLegal2Parentesc"
                    value={parentestOptions.find(option => option.value === newUser.userResponsableLegal2Parentesc)}
                    onChange={handleChange}
                    options={parentestOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userSistemaPagament">Sistema de pagament</label>
                <Select
                    id='userSistemaPagament'
                    name="userSistemaPagament"
                    value={id && newUser.userSistemaPagament && sistemaPagamentOptions.find(option => option.value === newUser.userSistemaPagament._id)}
                    onChange={handleChange}
                    options={sistemaPagamentOptions}
                    placeholder="- Selecciona -"
                    isClearable
                />
                <label for="userIBAN">IBAN</label>
                <input type='text' onChange={handleChangeCheck} name="userIBAN" value={newUser.userIBAN}></input>
                <label for="userObservacions">Observacions</label>
                <textarea onChange={handleChangeCheck} name="userObservacions" value={newUser.userObservacions}></textarea>
                <div className={styles.btn_container}>
                    <button className={styles.btn_add} type='submit'>{id ? "Guardar" : "Crear"}</button>
                </div>
            </form>
        </div>
    )
}

export default NewUser