import { useEffect, useState } from 'react'
import { LocalStorage } from '../../services/localStorage/LocalStorage.services'
import moment from 'moment'

import Porlet from '../../components/Porlet/Porlet'
import Filter from '../../components/Filter/Filter'

import getOperations from '../../services/operations/operations'

import styles from './Home.module.css'

const Home = () => {

  const [ yearSelect, setYearSelect ] = useState(moment().year())
  const [ data, setData ] = useState([])
  const [ pending, setPending ] = useState(false)

  useEffect(() => {
    setPending(true)
    const id = LocalStorage.getItem("id")
    const year = yearSelect
    getOperations(id, year)
    .then(data => {
      setData(data)
    })
    setPending(false)
  },[yearSelect])

  let datesInfo = [
    {
      name: "Nº Usuaris Actius",
      data: data.numUsuarisActius,
      link: "/usuaris",
      type: "info",
      icon: "fa-user"
    },
    {
      name: "Nº Usuaris Totals",
      data: data.numUsuarisTotals,
      link: "/professionals",
      type: "info",
      icon: "fa-users"
    },
    {
      name: "Nº Professionals",
      data: data.numProfessionals,
      link: "/professionals",
      type: "info",
      icon: "fa-user-tie"    
    },
  ]

  let datesAlert = [
    {
      name: "Nº Seguiments",
      data: data.numSeguiments,
      link: "/seguiments",
      type: "alerta",
      icon: "fa-clipboard"
    },
    {
      name: "Nº Seguiments Pendents",
      data: data.numSeguimentsPendents,
      link: "/seguiments",
      type: "alerta",
      icon: "fa-clock",
    },
    {
      name: "Nº Seguiments Endarrerits",
      data: data.numSeguimentsEnderrarits,
      link: "/seguiments",
      type: "alerta",
      icon: "fa-user-xmark",
    },
    {
      name: "Nº Seguiments Tancats",
      data: data.numSeguimentsTancats,
      link: "/seguiments",
      type: "alerta",
      icon: "fa-user-check"
    },
  ]

  // Función para hacer funcionar el filtro de elementos => por any
  const handleChangeYears = (value) => {
    setYearSelect(value)
  }

  // Placeholder del filtro de any
  const placeholderFilterYears = "Filtre any"

  // Array con los elementos del filtro de any
  const years = [ 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030 ]
  const dataYears = years.map(element => {
      return {
          label: element,
          value: element
      }
  })

  return (
    <div className={styles.container_home}>
      <div className={styles.user_info}>
        <div className={styles.filter_year}>
            <Filter
                handleChange={handleChangeYears}
                data={dataYears} 
                placeholderFilter={placeholderFilterYears}
                value={moment().year()}
                type="single"
            />
        </div>
      </div>
      <div className={styles.container_line}>
        {
          datesAlert.map((element, index) => {
            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon} filter={element.filter}></Porlet>
          })
        }
      </div>
      <div className={styles.container_line}>
        {
          datesInfo.map((element, index) => {
            return <Porlet key={index} data={pending ? pending : element.data } name={element.name} className={styles.portlet} link={element.link} type={element.type} icon={element.icon} filter={element.filter}></Porlet>
          })
        }
      </div>
    </div>
  )
}

export default Home